var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-card',{staticClass:"pa-5",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-card',{staticClass:"py-3",attrs:{"tile":"","flat":""}},[_c('h1',{staticClass:"mb-2"},[_vm._v("Life’s not about a job, it’s about purpose.")]),_c('h5',{staticClass:"subtitle-1 mb-5"},[_vm._v(" Join our passionate community of solvers. Help organizations build trust and deliver sustained outcomes ")]),(!_vm.noOpeings)?_c('v-btn',{attrs:{"color":"primary","tile":""},on:{"click":function($event){return _vm.$refs.search.focus()}}},[_vm._v(" Search jobs "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-magnify")])],1):_vm._e()],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-img',{attrs:{"contain":"","src":"/img/career.svg"}})],1):_vm._e()],1)],1)],1),(!_vm.noOpeings)?_c('v-container',[_c('v-card',{staticClass:"pa-5",attrs:{"flat":"","id":"search-jobs"}},[_c('v-card-text',[_c('v-text-field',{ref:"search",attrs:{"outlined":"","label":"Search","placeholder":"Search by Title or keyword","color":"primary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-row',[(_vm.jobs.length !== 0)?_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"},on:{"change":_vm.selectJobs}},[_vm._l((_vm.search.length > 0
                    ? _vm.filteredItems
                    : _vm.jobs),function(item,index){return [_c('v-list-item',{key:item.code,attrs:{"three-line":""},scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold primary--text overline"},[_vm._v(_vm._s(item.fullTitle))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.location)+" ")]),_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.getCategory(item.category))+" ")])],1),_c('v-list-item-action',[_c('v-list-item-action-text',{staticClass:"mb-5 primary--text"},[_vm._v(" "+_vm._s(item.contractType?.description)+" ")]),_c('v-btn',{attrs:{"text":!active,"to":{
                            name: 'Job',
                            params: {
                              jobid: item.jobID,
                              vacancyid: item.code,
                            },
                          }}},[_vm._v(" View Job "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1)],1)]}}],null,true)}),(index < _vm.jobs.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2)],1)],1):_vm._e(),(_vm.jobs.length === 0)?_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-skeleton-loader',{attrs:{"type":"card-heading, list-item-two-line, list-item-two-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"}})],1):_vm._e(),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('selectedJobCard',{attrs:{"specificJob":_vm.selectedJob ? _vm.selectedJob : _vm.jobs[0]}})],1)],1)],1)],1)],1):_c('v-container',[_c('v-card',{staticClass:"pa-5 style-center",attrs:{"flat":"","height":"400"}},[_c('div',[_c('v-img',{attrs:{"max-height":"300","max-width":"300","contain":"","src":"/img/emptybox.svg"}}),_c('h5',{staticClass:"subtitle-1 mb-5"},[_vm._v(" We do not have job openings currently, Please visit next time to check again ")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }