<template>
  <div>
    <v-card elevation="0" v-if="bio.length">
      <v-row>
        <v-col md="4" sm="12">
          <v-card elevation="0">
            <div class="d-flex justify-center">
              <v-avatar size="120">
                <v-img
                  v-if="ProfilePicture"
                  :src="ProfilePicture"
                  height="120px"
                  class="rounded"
                  contain
                ></v-img>

                <v-img
                  :src="user.avatar"
                  height="100px"
                  class="rounded"
                  contain
                  v-else-if="user.avatar !== null"
                />

                <v-img
                  src="../../../../assets/profile.jpg"
                  height="100px"
                  class="rounded"
                  contain
                  v-else
                />
              </v-avatar>
            </div>

            <v-card-subtitle v-if="editProfilePic" class="text-center">
              Attach a passport Size colored photo
            </v-card-subtitle>

            <v-card-actions>
              <v-file-input
                outlined
                dense
                ref="Image"
                type="file"
                v-model="formData.Image"
                v-if="editProfilePic"
                @change="attachProfilePicture"
                accept="image/png, image/jpeg"
                append-icon="mdi-paperclip"
              >
                <template v-slot:label>
                  <div>Attach Photo <span class="red--text">*</span></div>
                </template>
              </v-file-input>
              <v-btn
                text
                color="primary"
                class="text-capitalize"
                block
                v-if="!editProfilePic"
                @click="editProfilePic = true"
              >
                Update Profile Picture
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col md="8" sm="12">
          <v-form v-model="isValid" ref="bioForm">
            <v-text-field
              outlined
              dense
              v-model="formData.firstName"
              :rules="rules.Field"
              ref="FirstName"
              placeholder="First Name"
              persistent-placeholder
            >
              <template v-slot:label>
                <div>First Name <span class="red--text">*</span></div>
              </template>
            </v-text-field>

            <v-text-field
              outlined
              dense
              v-model="formData.secondName"
              persistent-placeholder
              placeholder="Middle Name"
              ref="SecondName"
            >
              <template v-slot:label>
                <div>Middle Names <span class="red--text">*</span></div>
              </template>
            </v-text-field>

            <v-text-field
              outlined
              dense
              v-model="formData.lastName"
              :rules="rules.Field"
              persistent-placeholder
              placeholder="Last Name"
              ref="LastName"
            >
              <template v-slot:label>
                <div>Last Name <span class="red--text">*</span></div>
              </template>
            </v-text-field>

            <v-row class="mt-n3">
              <v-col md="6" cols="12">
                <v-select
                  dense
                  outlined
                  :items="['Male', 'Female', 'Other']"
                  v-model="formData.gender"
                  :rules="rules.genderSelectField"
                  ref="Gender"
                >
                  <template v-slot:label>
                    <div>Gender <span class="red--text">*</span></div>
                  </template>
                </v-select>
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field
                  outlined
                  dense
                  type="date"
                  v-model="formData.dateOfBirth"
                  :rules="rules.Field"
                  ref="DateOfBirth"
                >
                  <template v-slot:label>
                    <div>Date of Birth <span class="red--text">*</span></div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col md="6" cols="12">
                <v-autocomplete
                  dense
                  outlined
                  :items="countries"
                  :item-value="(item) => item.code"
                  :item-text="(item) => item.displayName"
                  v-model="formData.nationality"
                  :rules="rules.Field"
                  ref="nationality"
                >
                  <template v-slot:label>
                    <div>Nationality <span class="red--text">*</span></div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col md="6" cols="12">
                <v-autocomplete
                  dense
                  outlined
                  :items="countries"
                  :item-value="(item) => item.code"
                  :item-text="(item) => item.displayName"
                  v-model="formData.countryCode"
                  :rules="rules.Field"
                  ref="countryCode"
                >
                  <template v-slot:label>
                    <div>Country <span class="red--text">*</span></div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col md="6" cols="12">
                <v-autocomplete
                  dense
                  outlined
                  :items="
                    counties.filter(
                      (county) => county.countryCode === formData.countryCode
                    )
                  "
                  v-model="formData.countyCode"
                  :item-value="(item) => item.countyNo"
                  :item-text="(item) => item.countyName"
                  :rules="rules.Field"
                  ref="countyCode"
                >
                  <template v-slot:label>
                    <div>County <span class="red--text">*</span></div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col md="6" cols="12">
                <v-autocomplete
                  dense
                  outlined
                  :items="
                    cities.filter(
                      (city) => city.countryRegionCode === formData.countryCode
                    )
                  "
                  v-model="formData.city"
                  :item-value="(item) => item.city"
                  :item-text="(item) => item.city"
                  :rules="rules.Field"
                  ref="city"
                >
                  <template v-slot:label>
                    <div>City <span class="red--text">*</span></div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="formData.ethnicity"
                  ref="ethnicBackground"
                >
                  <template v-slot:label>
                    <div>
                      Ethnic Background <span class="red--text">*</span>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="6" md="6" sm="12">
                <div class="anyFormOfDisability_container">
                  <div><span>Any Form of Disability </span></div>
                  <div class="check-box">
                    <input
                      type="checkbox"
                      v-model="formData.anyFormOfDisability"
                      :checked="formData.anyFormOfDisability"
                    />
                  </div>
                </div>
              </v-col>
              <v-col md="6" cols="12" v-if="formData.anyFormOfDisability">
                <v-text-field
                  outlined
                  dense
                  type=""
                  v-model="formData.disabilityDescription"
                  :rules="rules.Field"
                  ref="disabilityDescription"
                >
                  <template v-slot:label>
                    <div>
                      Disability description<span class="red--text">*</span>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <ContactDialog ref="contactDialog" />
        </v-col>
      </v-row>
    </v-card>
    <v-card flat v-else>
      <v-row>
        <v-col md="4" sm="12" cols="12">
          <v-skeleton-loader type="avatar" />
        </v-col>
        <v-col md="8" sm="12" cols="12">
          <v-skeleton-loader v-for="n in 7" :key="n" type="text" />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { AuthService } from "../../../auth";
import ContactDialog from "../contact/ContactDialog.vue";

export default {
  name: "BioDialog",
  components: { ContactDialog },
  data: function () {
    return {
      image_url: "",
      editable: true,
      edit: false,
      isValid: false,
      formData: {
        Image: undefined,
        email: "",
        firstName: "",
        lastName: "",
        nationality: "KE",
        profileID: "",
        secondName: "",
        gender: "",
        dateOfBirth: "",
        base64ProfilePic: undefined,
        anyFormOfDisability: false,
        disabilityDescription: "",
        ethnicity: "",
        city: "",
        countyCode: "",
        countryCode: "",
      },
      getImageinbs64: "",
      editProfilePic: false,
    };
  },

  mounted() {
    this.$store.dispatch("Profile/getBio");
    this.$store.dispatch("Profile/getContact");
    this.$store.dispatch("Profile/getCountries");
    this.$store.dispatch("Profile/getCities");
    this.$store.dispatch("Profile/getCounties");
  },

  computed: {
    rules() {
      return {
        profImage: [
          (v) => {
            return this.editable &&
              AuthService.user.profile &&
              !AuthService.user.profile.picture
              ? !!v || "Profile Picture is required"
              : true;
          },
          (v) => {
            return this.editable && !!v
              ? v.size < 10000000 || "Attachment should be less than 10MB"
              : true;
          },
          (v) => {
            return this.editable && !!v
              ? v.type.includes("image") || "Attachment must be an image"
              : true;
          },
        ],
        Field: [(v) => !!v || "Field is required"],
        genderSelectField: [(v) => !!v || "Gender is required"],
      };
    },
    user() {
      return AuthService.user;
    },
    bio() {
      return this.$store.getters["Profile/bioGetters"]("bio");
    },
    contact() {
      return this.$store.getters["Profile/contactGetters"]("contact");
    },
    countries() {
      return this.$store.getters["Profile/bioGetters"]("countries");
    },
    cities() {
      return this.$store.getters["Profile/bioGetters"]("cities");
    },
    counties() {
      return this.$store.getters["Profile/bioGetters"]("counties");
    },
    ProfilePicture() {
      let profile = this.bio[0];

      if (this.image_url) return this.image_url;

      return profile
        ? profile.base64ProfilePic
          ? `data:image/jpeg;base64,${profile.base64ProfilePic}` ||
            `data:image/png;base64,${profile.base64ProfilePic}`
          : ""
        : "";
    },

    profImage() {
      return `data:image/jpeg;base64,${this.bio[0].base64ProfilePic}`;
    },
  },

  methods: {
    saveProfile: function () {
      if (!this.isValid) {
        this.$refs.bioForm.validate();
      } else {
        const data = { ...this.formData };
        data.Image = data.base64ProfilePic;
        data.profileID = this.user.profileID;
        this.$store.dispatch("Profile/saveBio", data);
        this.$refs.contactDialog.saveContact();
      }
    },
    convertTobase64: async function (url) {
      const data = await fetch(url);
      const blob = await data.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const base64data = reader.result;
        console.log(base64data);
        const strImage = base64data.replace(/^data:image\/[a-z]+;base64,/, "");
        this.formData.base64ProfilePic = strImage;
        return base64data;
      };
    },
    attachProfilePicture: function () {
      this["image_url"] = URL.createObjectURL(this.formData["Image"]);
      this.getImageinbs64 = this.convertTobase64(this.image_url);
    },
    redirect() {
      this.$router.push({
        name: "Home",
      });
    },
    editProfile: function () {
      if (!this.isValid) {
        this.$refs.bioForm.validate();
        this.$refs.contactDialog.$refs.contactForm.validate();
      } else {
        if (!this.$refs.contactDialog.$refs.contactForm.validate()) {
          return;
        } else {
          const data = { ...this.formData };
          data.Image = data.base64ProfilePic;
          data.profileID = this.user.profileID;
          this.$store.dispatch("Profile/saveBio", data);
          this.$refs.contactDialog.updateProfileContact();
        }
      }
    },
    checkBio() {
      return this.bio.length > 0;
    },
  },

  watch: {
    bio: {
      handler: function () {
        if (this.bio.length > 0) {
          this.formData = { ...this.bio[0] };
          if (this.formData.firstName === "" && this.user.firstName !== "") {
            this.formData.firstName = this.user.firstName;
            this.formData.lastName = this.user.lastName;
          }
          if (this.formData.dateOfBirth === "0001-01-01") {
            this.formData.dateOfBirth = "";
          }
          if (this.formData.gender.length <= 1) {
            this.formData.gender = "";
          }
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.transform {
  text-transform: uppercase;
}
.anyFormOfDisability_container {
  display: flex;
  align-items: center;
  justify-content: center;
  & > *:first-child {
    margin-right: 0.8rem;
  }
}

input:checked[type="checkbox"] {
  background: #7da6ff;
}
.check-box {
  transform: scale(0.8);
}
input[type="checkbox"] {
  position: relative;
  appearance: none;
  width: 100px;
  height: 50px;
  background: #ccc;
  border-radius: 50px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.4s;
}

input:checked[type="checkbox"] {
  background: #7da6ff;
}

input[type="checkbox"]::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
  transition: 0.4s;
}

input:checked[type="checkbox"]::after {
  left: 50%;
}
</style>
